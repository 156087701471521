<template>
  <div class="vc-w-100 vc-home-forward">
    <v-row class="vc-w-100 ma-0 content text-center vc-flex-center">
      <v-col cols="12" class="pa-0">
        <img height="48px" src="@/assets/img/white-logo-venx.png" alt="" />
      </v-col>

      <v-col v-if="!lastPerson" cols="12" class="pa-0 pa-2 primaryLight mt-3">
        <v-alert dense border="left" color="primary" type="info" class="mb-0">
          Você <strong>precisa</strong> gerar uma ficha para realizar o
          encaminhamento.
        </v-alert>
      </v-col>

      <v-col cols="12" class="pa-0 mt-7 mb-5">
        <v-autocomplete
          class="vc-w-100"
          v-model="selectedPlaceId"
          solo
          :background-color="!lastPerson ? 'vcGrey' : 'primaryLight'"
          label="Local"
          :items="places"
          item-text="name"
          item-value="_id"
          hide-details
          :disabled="!lastPerson"
        >
          <template v-slot:selection="data">
            {{ `${data.item.name}` }}
          </template>
          <!-- <template v-slot:item="data">
            <template>
              <v-list-item-content>
                <v-list-item-title
                  v-html="`${data.item.name}`"
                ></v-list-item-title>
              </v-list-item-content>
            </template>
          </template> -->
        </v-autocomplete>
      </v-col>
      <v-col cols="12" class="pa-0 mb-5">
        <v-text-field
          v-model="timePerson"
          type="time"
          :disabled="!lastPerson"
          class="vc-w-100"
          :background-color="!lastPerson ? 'vcGrey' : 'primaryLight'"
          solo
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" class="pa-0 mb-5">
        <v-text-field
          v-model="namePerson"
          :disabled="!lastPerson"
          class="vc-w-100"
          :background-color="!lastPerson ? 'vcGrey' : 'primaryLight'"
          label="Digite o nome da pessoa"
          solo
          hide-details
        ></v-text-field>
      </v-col>
      <!-- <v-col cols="4" class="rounded-tr-0 pa-0 mb-5">
        <v-switch
        class="black-label"
          v-model="preferential"
          :disabled="!lastPerson"
          label="Preferêncial"
        ></v-switch>
      </v-col> -->
      <v-col
      
        v-if="showAlertPerson || showAlert"
        cols="12"
        class="pa-0 pa-2 primaryLight vc-br-10 mb-5"
      >
        <v-alert
          v-if="showAlertPerson"
          dense
          border="left"
          color="primary"
          type="info"
          class="mb-0"
        >
          Você <strong>precisa</strong> chamar uma ficha antes de realizar esta
          ação.
        </v-alert>
        <v-alert
          v-if="showAlert"
          dense
          border="left"
          color="primary"
          type="info"
          class="mb-0 mt-2"
        >
          Preencha <strong>todos</strong> os campos acima para efetuar a ação.
        </v-alert>
      </v-col>
      <v-col cols="12" class="pa-0">
        <v-btn
          max-width="200"
          color="primary"
          class=" white--text vc-w-100 vc-br-10"
          :disabled="!lastPerson"
          @click="forwardClient"
        >
          <div class="vc-text-button">encaminhar</div>
          <v-icon size="20" class="ml-3" right color="white">
            mdi-send
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'vc-forward',
  data() {
    return {
      selectedPlace: '',
      namePerson: '',
      timePerson: null,
      selectedPlaceId: '',
      showAlert: false,
      showAlertPerson: false,
      preferential: false,
    };
  },
  props: {
    places: { type: Array, default: () => {} },
    lastPerson: { type: Object, default: () => {} },
  },

  methods: {
    forwardClient() {
      this.$store.commit('SET_ALL_QUEUES');
      this.showAlert = false;
      !this.lastPerson
        ? (this.showAlertPerson = true)
        : (this.showAlertPerson = false);
      if (this.selectedPlaceId && this.namePerson) {
        console.log('lastPerson', this.lastPerson._id);
        let body = {
          name: this.namePerson,
          people_id: this.lastPerson._id,
          place_id: this.selectedPlaceId,
        };
        if (this.timePerson) {
          let updated_time = moment(
            moment(new Date()).format('YYYY-MM-DD') + ' ' + this.timePerson
          ).format('YYYY-MM-DD HH:mm:00-3');
          body.created_at = updated_time;
        }
        this.$store.dispatch('forwardClient', body).then(() => {
          this.namePerson = '';
          this.selectedPlaceId = '';
        });
      } else {
        this.showAlert = true;
      }
    },
  },
};
</script>
