import http from '../api'

let getAllPlaces = () => {
    return http.get(`places?page=0`)
}

let setTokensByPlace = (stringUrl) => {
    // return http.get(`queue-tickets`)
    return http.get(`queue/?called=TRUE${stringUrl}`)
}

let callClient = (stringUrl, data) => {
    return http.put(`call-on-the-panel/${stringUrl}`, data)
}

let deleteeQueue = () => {
    return http.delete(`queue`)
}

let forwardClient = (data) => {
    return http.put(`queue/${data.people_id}`, { place: data.place_id, name: data.name, created_at: data.created_at })
}

let generateQueue = (data) => {
    return http.post(`queue`, data)
}

export default {
    getAllPlaces,
    setTokensByPlace,
    callClient,
    forwardClient,
    deleteeQueue,
    generateQueue,
}