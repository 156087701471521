import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import Notifications from 'vue-notification'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'

// main scss
import './assets/scss/main.scss'


//Socket IO
import VueSocketIO from 'vue-socket.io'
import SocketIO from "socket.io-client"

Vue.use(Notifications)

Vue.use(new VueSocketIO({
  debug: process.env.NODE_ENV === 'development',
  connection: SocketIO(`${process.env.VUE_APP_API_URL}/vclinic`, {
    //query: { },
    autoConnect: true
  }),
  vuex: {
    store,
    actionPrefix: 'SOCKET_',
    mutationPrefix: 'SOCKET_'
  },
  options: [
    {  transports: ['websocket']  }
  ]
}))

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  sockets:{
    connect() {
      console.log('Conectando ao socket')
      //this.$store.dispatch('statusConectado', true)
    },
    disconnect() {
      console.log('Disconectando o socket')
      //this.$store.dispatch('statusConectado', false) 
    }
  },
  render: h => h(App)
}).$mount('#app')
