<template>
  <div class="vc-home-select-message">
    <div>
      <img height="48px" src="@/assets/img/white-logo-venx.png" alt="" />
    </div>
    <div class="vc-text-bold white--text mt-2">
      Por favor, selecione o local ao qual para iniciar o sistema
    </div>
    <div class="mt-5">
      <v-autocomplete
        v-model="selectedPlace"
        solo
        background-color="primaryLight"
        label="Local"
        :items="places"
        item-text="name"
        item-value="_id"
        dense
        hide-details
        return-object
      >
        <template v-slot:selection="data">
          {{ `${data.item.name}` }}
        </template>
        <template v-slot:item="data">
          <template>
            <v-list-item-content>
              <v-list-item-title
                v-html="`${data.item.name}`"
              ></v-list-item-title>
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>
    </div>

    <v-btn
      :disabled="!selectedPlace"
      color="primary"
      class="white--text mt-5"
      @click="setTokensByPlace"
    >
      <div class="vc-text-button">confirmar</div>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'select-place-message',
  props: {
    places: { type: Array, default: () => {} },
    placeToHeader: { type: Object, default: () => {} },
  },
  data() {
    return {
      selectedPlace: '',
    };
  },
  methods: {
    setTokensByPlace() {
      this.$store.dispatch('setTokensByPlace', this.selectedPlace);
    },
  },
};
</script>
