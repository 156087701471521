<template>
  <div class="vc-w-100 vc-home-info-table">
    <v-row class="vc-w-100 vc-flex-center ma-0">
      <v-col cols="6" class="vc-flex-center pa-0">
        <v-btn
          rounded
          color="primary"
          class="white--text"
          style="padding:40px"
          @click="beforeGenerate"
        >
          <h1 class="vc-text-button">Novo Paciente</h1>
    <!--      <v-icon large color="white">
            mdi-ticket-account
          </v-icon> -->
        </v-btn>
      </v-col>
      <v-col
        style="font-size: 15px;"
        v-if="lastPerson"
        cols="12"
        class="vc-text-bold white--text"
      >
        <p>Paciente gerado (a)!</p>
      </v-col>
    </v-row>

    <v-dialog
      v-if="lastPerson"
      v-model="dialog"
      width="50vh"
    >
      <v-card>
        <v-card-text>
          <v-row class="py-5">
            <v-col cols="12" align="center">
              <h1>Atenção!</h1>
            </v-col>
            <v-col>
                <div class="font-weight-bold">
                  A ficha 
                  <span class="text-uppercase" style="color: darkblue"> {{ lastPerson.type_key + lastPerson.key }} </span>
                  foi gerada e ainda não foi encaminhada, gerar outra ficha sem encaminhá-la irá descartá-la, deseja continuar mesmo assim?
                </div>
            </v-col>
          </v-row>
           
        </v-card-text>
        <v-card-actions class="justify-space-between">
          <v-btn color="" @click="dialog = false">Fechar</v-btn>
          <v-btn color="warning" @click="dialog = false; generateQueue()">Continuar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'generate-queue',
  props: {
    lastPerson: { type: Object, default: () => {} },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    beforeGenerate(){
      if(this.lastPerson){this.dialog = true}
      else{this.generateQueue()}
    },
    generateQueue() {
      this.$store.dispatch('generateQueue', {
        type_key: 'C',
      });
    },
    format_time(time){
      return moment(time).format('HH:mm') 
    },
  },
};
</script>
