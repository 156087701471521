var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vc-w-100 vc-home-info-table"},[(!_vm.currentPlace.forwarding)?_c('v-row',{staticClass:"vc-w-100 ma-0 vc-flex-center mb-3"},[_c('img',{attrs:{"height":"48px","src":require("@/assets/img/white-logo-venx.png"),"alt":""}})]):_vm._e(),_c('v-row',{staticClass:"vc-w-100 ma-0 mb-3"},[(_vm.lastPerson)?_c('v-col',{staticClass:"vc-flex-center vc-text-bold pa-0 white--text",staticStyle:{"font-size":"25px"},attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.repeating ? ("Último Paciente chamado: " + (_vm.lastPerson.name)) : ("Chamando Paciente novamente: " + (_vm.lastPerson.name)))+" ")]):_vm._e()],1),_c('v-row',{staticClass:"vc-w-100 vc-flex-center ma-0"},[_c('v-col',{staticClass:"vc-flex-center pa-0",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":"","color":"primaryDark"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text",attrs:{"rounded":"","disabled":_vm.people.length === 0,"color":"primary"},on:{"click":_vm.callClient}},'v-btn',attrs,false),on),[_c('div',{staticClass:"vc-text-button"},[_vm._v("próximo")]),_c('v-icon',{attrs:{"size":"20","right":"","color":"white"}},[_vm._v(" mdi-arrow-right-thick ")])],1)]}}])},[_c('span',[_vm._v(" Chamar o(a) "),_c('strong',[_vm._v("próximo(a)")]),_vm._v(" pessoa da lista. ")])])],1),_c('v-col',{staticClass:"vc-flex-center pa-0",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":"","color":"primaryDark"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:" white--text",attrs:{"disabled":!_vm.lastPerson,"rounded":"","color":"primary"},on:{"click":_vm.repeatPerson}},'v-btn',attrs,false),on),[_c('div',{staticClass:"vc-text-button"},[_vm._v("repetir")]),_c('v-icon',{attrs:{"size":"20","right":"","color":"white"}},[_vm._v(" mdi-repeat ")])],1)]}}])},[(_vm.lastPerson)?_c('span',[_vm._v(" Chamar "),_c('strong',[_vm._v(_vm._s(_vm.lastPerson.name ? ("" + (_vm.lastPerson.name)) : ("" + (_vm.lastPerson.type_key) + (_vm.lastPerson.key))))]),_vm._v(" novamente. ")]):_vm._e()])],1)],1),_c('v-row',{staticClass:"vc-w-100 table ma-0"},[_c('v-col',{staticClass:"mt-5 pa-0",attrs:{"cols":"12"}},[(_vm.people.length > 0)?_c('v-row',{staticClass:"vc-w-100 ma-0"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"6"}},[_c('div',{staticClass:"white--text vc-text-bold text-center"},[_vm._v("NOME")])]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"3"}},[_c('div',{staticClass:"white--text vc-text-bold text-center"},[_vm._v("HORA")])]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"3"}},[_c('div',{staticClass:"white--text vc-text-bold text-center"},[_vm._v(" SENHA ")])])],1):_vm._e()],1),_c('v-col',{staticClass:"pa-0",staticStyle:{"position":"relative"},attrs:{"cols":"12"}},[_c('div',{staticClass:"vc-w-100 vc-people-table-content smooth-croll"},_vm._l((_vm.people),function(person,i){return _c('v-row',{key:("" + i + (person.name)),staticClass:"vc-w-100 table ma-0 mt-1 mb-1 text-center",attrs:{"id":("" + i + (_vm.people.length))}},[_c('div',{staticClass:"vc-flex-center ml-1"},[_c('v-btn',{staticClass:"mx-2",attrs:{"width":"20","height":"20","fab":"","dark":"","color":"primaryLight"},on:{"click":function($event){return _vm.callClientSpecific(
                  person.key,
                  person.type_key,
                  person.name ? person.name : null,
                  person._id
                )}}},[_c('v-icon',{attrs:{"x-small":"","color":"primaryDark"}},[_vm._v(" mdi-account-voice ")])],1)],1),_c('v-col',{staticClass:"table-row vc-flex-center vc-text-bold primaryDark--text pa-0",attrs:{"cols":"6"}},[_vm._v(_vm._s(person.name || '-'))]),_c('v-col',{staticClass:"table-row vc-flex-center vc-text-bold primaryDark--text ml-1 mr-1 pa-0",attrs:{"cols":"2.5"}},[_vm._v(_vm._s(person.ticketHour))]),_c('v-col',{staticClass:"table-row vc-flex-center vc-text-bold primaryDark--text pa-0",attrs:{"cols":"2.5"}},[_vm._v(_vm._s(person.key && person.type_key ? ("" + (person.type_key) + (person.key)) : '-'))])],1)}),1),(_vm.people.length > 6 && !_vm.goTopCheck)?_c('a',{staticClass:"vc-w-100 text-end",staticStyle:{"position":"absolute","bottom":"10px","right":"10px"},attrs:{"id":"goMoveButton"},on:{"click":_vm.goMoveTable}},[_c('v-btn',{staticClass:"mx-2",attrs:{"width":"30","height":"30","fab":"","dark":"","color":"primaryDark"}},[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v(" mdi-arrow-down-thick ")])],1)],1):(_vm.people.length > 6 && _vm.goTopCheck)?_c('a',{staticClass:"vc-w-100 text-end",staticStyle:{"position":"absolute","bottom":"10px","right":"10px"},attrs:{"id":"goMoveButton"},on:{"click":_vm.goTop}},[_c('v-btn',{staticClass:"mx-2",attrs:{"width":"30","height":"30","fab":"","dark":"","color":"primaryDark"}},[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v(" mdi-arrow-up-thick ")])],1)],1):_vm._e(),(_vm.people.length >= 1 && _vm.currentPlace.type === 'KEYALL')?_c('div',{staticClass:"vc-flex-center ml-1 mt-10"},[_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error"}},'v-btn',attrs,false),on),[_vm._v("Zerar Fila")])]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v("Zerar Fila")]),_c('v-card-text',[_c('div',{staticClass:"mt-10"},[_c('v-alert',{attrs:{"elevation":"13","outlined":"","prominent":"","type":"warning"}},[_vm._v("Aviso esta ação irá remover as fichas de todas as salas, tem certeza que quer prosseguir?")])],1)]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.deleteQueue}},[_vm._v("Confirmar")]),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){dialog.value = false}}},[_vm._v("Cancelar")])],1)],1)]}}],null,false,1466335323)})],1):_vm._e(),(_vm.people.length === 0)?_c('v-row',{staticClass:"vc-w-100 table ma-0 mt-1 mb-1 text-center"},[_c('v-col',{staticClass:"table-row vc-flex-center primaryDark--text pa-4",staticStyle:{"border-radius":"3px"},attrs:{"cols":"12"}},[_c('v-alert',{staticClass:"mb-0",attrs:{"dense":"","border":"left","type":"info","color":"primary"}},[_vm._v(" Não existe nenhum cliente a "),_c('strong',[_vm._v("espera")]),_vm._v(" no momento. ")])],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }