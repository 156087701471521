<template>
  <div class="vc-home-header pt-2 pb-2">
    <div v-if="currentPlace" class="vc-home-header-dropdown">
      <v-btn
        style="display: flex; justify-content: center; align-items: center;"
        height="20px"
        depressed
        color="primary"
      >
        <div class="vc-home-header-button-text">
          {{
            selectedPlace
              ? `${selectedPlace.name}`
              : `${currentPlace.name}`
          }}
        </div>
        <v-icon right dark>
          mdi-chevron-down
        </v-icon>
      </v-btn>
      <div class="dropdown-content pa-2" >
        <div style="max-height: 200px; overflow-y: scroll; ">

        <v-btn
        class="vc-w-100"
          v-for="(place, i) in places"
          :key="`${place.name}${i}`"
          text
          color="primary"
          @click="selectPlace(place)"
        >
          <div>
            {{ `${place.name}` }}
          </div>
        </v-btn>
        </div>
      </div>
    </div>

    <v-dialog v-model="openSelectDialog" persistent max-width="400">
      <v-card class="primaryLight">
        <div class="vc-w-100 vc-flex-center mt-1">
          <v-icon size="85px" color="primaryDark">
            mdi-alert-circle-outline
          </v-icon>
        </div>
        <v-card-title class="headline vc-text-primaryDark pt-2 pb-2">
          Deseja mesmo trocar de local?
        </v-card-title>
        <v-card-text class="vc-text-bold pb-3"
          >Ao confirmar a ação você irá trocar de
          {{ `${currentPlace.name}` }} para
          {{ `${placeStore.name}` }}</v-card-text
        >
        <v-card-text class="pb-0">
          <v-alert border="left" color="primary" type="info">
            Ao confirmar a ação, o histórico de ultima ficha será perdido.
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="closeDialog">
            <div class="vc-text-button">fechar</div>
          </v-btn>
          <v-btn color="primary" @click="setTokensByPlace">
            <div class="vc-text-button">confirmar</div>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'vc-home-header',
  data() {
    return {
      selectedPlace: '',
      placeStore: '',
      openSelectDialog: false,
    };
  },
  props: {
    places: { type: Array, default: () => {} },
    currentPlace: { type: Object, default: () => {} },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (this.currentPlace) {
        this.selectedPlace = this.currentPlace;
      }
    },
    setTokensByPlace() {
      this.selectedPlace = this.placeStore;
      this.$store.dispatch('setTokensByPlace', this.selectedPlace).then(() => {
        this.closeDialog();
      });
    },
    selectPlace(place) {
      if (place._id != this.currentPlace._id) {
        this.openSelectDialog = true;
        this.placeStore = place;
      }
    },
    closeDialog() {
      this.openSelectDialog = false;
      this.placeStore = '';
    },
  },
};
</script>

<style>
/* Add your styles here */
</style>
