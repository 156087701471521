<template>
  <div class="vc-home vc-flex-center">
    <template>
      <div>
        <audio ref="alertSound">
          <source src="../assets/notify.wav" type="audio/mpeg" />
        </audio>
      </div>
    </template>
    <home-header style="justify-content:center; display:flex" :places="allPlaces" :currentPlace="currentPlace" />

    <v-row v-if="currentPlace" class="vc-home-content ma-0 ml-5 mr-5 " align="center">
      <v-col v-if="currentPlace.type != 'KEYALL' && currentPlace.forwarding" cols="12" class="w-100 white w-100	" md="6">
        <info-table :currentPlace="currentPlace" :lastPerson="lastPerson" />
      </v-col>

      <v-col v-if="currentPlace.type == 'KEYALL'" cols="12" md="6" align="center">
        <generate-queue :lastPerson="lastPerson" />
      </v-col>

      <v-col v-if="currentPlace.forwarding" cols="12" md="6" align="center">
        <forward :places="allPlaces" :lastPerson="lastPerson" />
      </v-col>
    </v-row>
    <v-row
      v-if="currentPlace && currentPlace.type != 'KEYALL' && !currentPlace.forwarding"
      class=" vc-home-content  mr-5 "
      align="center"
    >
      <v-col cols="12" class="w-100 	" md="6">
        <info-table :currentPlace="currentPlace" :lastPerson="lastPerson" />
      </v-col>
    </v-row>
    <v-row v-else-if="!currentPlace" class="vc-home-content ma-0 ml-5 mr-5">
      <v-col cols="12" class="pa-0 vc-flex-center">
        <select-place-message :places="allPlaces" />
      </v-col>
    </v-row>

    <Footer />
  </div>
</template>

<script>
import HomeHeader from '@/components/HomeComponents/Header.vue';
import InfoTable from '@/components/HomeComponents/InfoTable.vue';
import Forward from '@/components/HomeComponents/Forward.vue';
import Footer from '@/components/HomeComponents/Footer.vue';
import SelectPlaceMessage from '@/components/HomeComponents/SelectPlaceMessage.vue';
import GenerateQueue from '@/components/HomeComponents/GenerateQueue.vue';
import ls from 'local-storage';

export default {
  name: 'Home',
  components: {
    HomeHeader,
    InfoTable,
    Forward,
    Footer,
    SelectPlaceMessage,
    GenerateQueue
  },
  mounted() {
    this.init();
  },
  created() {
    if (ls.get('currentPlace')) {
      this.$store.commit('SET_CURRENT_PLACE', ls.get('currentPlace'));
      this.$store.dispatch('setTokensByPlace', this.currentPlace);
    }
    if (ls.get('lastPerson')) {
      this.$store.commit('SET_LAST_PERSON', ls.get('lastPerson'));
    }
  },
  watch: {
    currentPlace(newVal, oldVal) {
      this.$store.commit('SET_LAST_PERSON', null);
      this.sockets.unsubscribe('new_queue');
      if (oldVal) {
        console.log('teste oldval', oldVal);
        this.sockets.unsubscribe(`place_queue`);
        this.$socket.emit(`to_exit_place`, oldVal._id);
      }
      if (this.currentPlace.type === 'KEYALL') {
        this.sockets.subscribe('new_queue', data => {
          this.$store.commit('ALL_QUEUES', [data])
          this.sendTable(data);
          //this.$refs.alertSound.play();
          document.title = `Novo paciente (${this.allQueues.length})`;
        });
      } else {
        this.sockets.subscribe(`place_queue`, data => {
          this.sendTable(data);
          this.$refs.alertSound.play();
          this.$store.commit('ALL_QUEUES', [data]);
          console.log('TOTAL DE PESSOAS',this.allQueues.length);
          document.title = `Novo paciente (${this.allQueues.length})`;
        });
      }
      this.$socket.emit(`to_enter_place`, newVal._id);
    }
  },
  methods: {
    init() {
      this.$store.dispatch('getAllPlaces');
      this.sockets.subscribe('remove_queue', data => {
        this.$store.commit('SET_REMOVE_PERSON', data);
      });
    },
    sendTable(data) {
      this.$store.commit('SET_PUSH_PEOPLE', data);
    }
  },
  computed: {
    allPlaces() {
      return this.$store.getters.allPlaces;
    },
    people() {
      return this.$store.getters.people;
    },
    currentPlace() {
      return this.$store.getters.currentPlace;
    },
    lastPerson() {
      return this.$store.getters.lastPerson;
    },
    allQueues() {
      return this.$store.getters.allQueues;
    }
  }
};
</script>
