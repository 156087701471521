<template>
  <div class="vc-home-footer vc-w-100 pt-0 pb-0 pl-8 pr-8">
    <div class="white--text date">
      <!-- Quarta-feira 29 de Abril de 2021 - 10:00 -->
      {{ todayString }}
    </div>

    <div class="white--text">
      Versão 0.1.0
    </div>
  </div>
</template>

<script>
export default {
  name: 'vc-home-header',
  data: () => ({
    todayString: '',
    today: {
      day: '',
      month: '',
      year: '',
      weekDay: '',
      hour: '',
      minutes: '',
    },
    weekDays: {
      0: {
        name: 'Domingo',
      },
      1: {
        name: 'Segunda-feira',
      },
      2: {
        name: 'Terça-feira',
      },
      3: {
        name: 'Quarta-feira',
      },
      4: {
        name: 'Quinta-feira',
      },
      5: {
        name: 'Sexta-feira',
      },
      6: {
        name: 'Sábado',
      },
    },
    months: {
      0: {
        name: 'Janeiro',
      },
      1: {
        name: 'Fevereiro',
      },
      2: {
        name: 'Março',
      },
      3: {
        name: 'Abril',
      },
      4: {
        name: 'Maio',
      },
      5: {
        name: 'Junho',
      },
      6: {
        name: 'Julho',
      },
      7: {
        name: 'Agosto',
      },
      8: {
        name: 'Setembro',
      },
      9: {
        name: 'Outubro',
      },
      10: {
        name: 'Novembro',
      },
      11: {
        name: 'Dezembro',
      },
    },
  }),
  mounted() {
    this.setCurrentDate();
  },
  methods: {
    setCurrentDate() {
      let date = new Date();
      this.today.day = date.getDate();
      this.today.month = this.months[date.getMonth()].name;
      this.today.year = date.getFullYear();
      this.today.weekDay = this.weekDays[date.getUTCDay()].name;
      this.today.hour = date.getHours();
      this.today.minutes = String(date.getMinutes());
      if (this.today.minutes.length < 2) {
        this.today.minutes = `0${this.today.minutes}`;
      }
      this.todayString = `${this.today.weekDay}, ${this.today.day} de ${this.today.month} de ${this.today.year} - ${this.today.hour}:${this.today.minutes}`;
    },
  },
};
</script>
